import axios from 'axios';
import APIs from '@services/APIs';

const BASE_URL = APIs.API_URL;

export default {
  getName,
  find,
  verify,
  canHandleCountry,
  mapRemoteList,
  format
};

function getName() {
  return 'addy';
}

function canHandleCountry(country) {
  return country === 'NZ';
}

async function find({ address, country }) {
  const payload = {
    address,
    country
  };

  const source = axios.CancelToken.source();

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });
    return mapRemoteList(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function mapRemoteList(data) {
  return data.addresses.map((item) => ({ text: item.a }));
}

async function verify({ address, country }) {
  const payload = {
    address,
    country
  };
  const source = axios.CancelToken.source();

  return axios
    .post(`${BASE_URL}/address/validate/${getName()}`, payload, { cancelToken: source.token })
    .then(({ data }) => {
      return format(address, data);
    })
    .catch((err) => {
      console.error('resolved error exception', err);
      return format(address);
    });
}

function format(address, cleanseData = null) {
  if (!cleanseData) {
    throw new Error('Cannot validate address. No results found');
  }

  if (!cleanseData.address || !Object.keys(cleanseData.address)) {
    throw new Error('Address entered is not valid');
  }

  let { address: addrObject } = cleanseData;
  if (!addrObject) {
    addrObject = cleanseData;
  }

  const mappedData = {
    fullAddress: address,
    homeAddress: address,
    addressLine1: addrObject.address1 || '',
    addressLine2: addrObject.address2 || '',
    unit_number: addrObject.unitnumber || '',
    street_number: addrObject.numberfull || '',
    street_name: addrObject.street || '',
    street_type: '',
    city: addrObject.city ? addrObject.city : addrObject.mailtown,
    suburb: addrObject.suburb ? addrObject.suburb : '',
    postcode: addrObject.postcode || '',
    dpid: addrObject.dpid || '',
    country: 'NZL',
    address_api: getName()
  };

  if (!addrObject.address4 || addrObject.address2.includes('RD')) {
    mappedData.addressLine1 = addrObject.address1;
    mappedData.addressLine2 = addrObject.address2;
  } else {
    mappedData.addressLine1 = addrObject.displayline;
    mappedData.addressLine2 = '';
  }

  return { ...mappedData, provider: getName() };
}
