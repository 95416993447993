import appConfig from '../actions/appConfig';

const DEFAULT_FIELD_EDIT_LIMIT_COUNT = 3;

const initialState = { field_edit_limit_count: DEFAULT_FIELD_EDIT_LIMIT_COUNT };

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case appConfig.SET_APP_CONFIG:
      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
}
