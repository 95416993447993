/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';
import omit from 'lodash/omit';

import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import EditedAction from '@store/actions/edited';

import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';
import { isAgeEligible, selectEligibleAge } from '@js/domain/ageEligibility';
import { getAge, getCookie, setCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import {
  isDocumentExpired,
  isEligibleForPassportExpiredLogic,
  isExpiredIdAllowed,
  isPassportExpiredBy2Years
} from '@lib/utils/checkExpiredDocument';
import { AgeReview } from '@lib/components/v2/AgeReview';
import {
  ADDITIONAL_RECAPTURE_ALLOWED,
  DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY,
  ENABLE_CONFIRM_AGE,
  FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE,
  FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE,
  FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE,
  FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT,
  ID_NUMBER_EDITABLE
} from '@spotMobileConfig';
import {
  ConfirmConsent,
  ConfirmGoBack,
  EditTooManyFields,
  ExpiredID,
  ExpiredIDBy2Years,
  IdNumberNotEditable,
  SelectAnotherIdException,
  TooManyRetryAttempts
} from './VerifyDetails.errors';
import { Error500 } from '../../errors';
import { ChooseAnotherId, VerifyDetailsContent } from '../../components/Contents';

class VerifyDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    retake: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    location: PropTypes.string,
    verify: PropTypes.bool,
    flowType: PropTypes.string,
    appConfig: PropTypes.object,
    verifyDetails: PropTypes.object,
    isFlowV2DiffId: PropTypes.bool,
    setConfirmedInfo: PropTypes.func,
    onGoBack: PropTypes.func,
    setEditedFields: PropTypes.func,
    onChooseDiffId: PropTypes.func,
    isOldTurkishDL: PropTypes.bool,
    eligibleAge: PropTypes.number,
    extracted: PropTypes.object,
    lastConfirmedDetails: PropTypes.object,
    editedFields: PropTypes.array,
    onShowProofOfAddress: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    retake: () => null,
    idType: 'NZL_DRIVERLICENCE',
    token: '',
    verify: false,
    verifyDetails: {}
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState(props) {
    const { lastConfirmedDetails, verifyDetails, useIdNumber } = props;
    const state = {
      data: {
        checkConfirm: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      /* disable primary button */
      disabled: true,
      confirmed: false,
      error: null,
      loading: false,
      countryOfIssue: '',
      hasFormErrors: false,
      showConfirmLeaveEditing: false,
      showNoMiddleNameModal: false,
      backup: null,
      userEdited: false,
      useIdNumber,
      showSelectAnotherIdException: false,
      showChooseAnotherId: false
    };

    if (lastConfirmedDetails) {
      state.data = { ...lastConfirmedDetails };
      state.countryOfIssue = lastConfirmedDetails.countryOfIssue;
    } else {
      state.data = { ...verifyDetails };
      state.countryOfIssue = verifyDetails.countryOfIssue;
    }

    return state;
  }

  async handleConfirm({ isValid }) {
    const {
      location,
      flowType,
      appConfig,
      verifyDetails,
      isFlowV2DiffId,
      setFrontIDParams,
      retake,
      setConfirmedInfo,
      onGoBack,
      onNextStep,
      eligibleAge,
      editedFields,
      onShowProofOfAddress
    } = this.props;
    const { data: newData } = this.state;

    if (!isValid) {
      return;
    }

    const data = { ...newData, edited: editedFields };
    const { checkConfirm } = data;
    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    if (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }

    const { dateOfBirth, expiryDate, cardType, countryCode, unitNumber } = data;

    const age = getAge(dateOfBirth, true);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, eligibleAge)) {
      const error = {
        component: AgeReview,
        props: {
          eligibleAge,
          onTryAgain: () => this.setState({ error: null })
        }
      };
      this.setState({ error });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    let expiredIdButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          this.setState({ error: null });
        }
      },
      {
        label: localizedString('recaptureDocument'),
        onClick: () => {
          setFrontIDParams({});
          retake();
        }
      }
    ];
    if (isPassportExpiredBy2YearsFlag) {
      if (FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE) {
        expiredIdButtons = [
          {
            label: localizedString('tryAgain'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ];
      }
    } else if (FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE) {
      expiredIdButtons = [
        {
          label: localizedString('tryAgain'),
          onClick: () => {
            setFrontIDParams({});
            retake();
          }
        }
      ];
    }
    const error = {
      component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
      props: {
        buttons: expiredIdButtons
      }
    };
    if (isExpired && !isExpiredIdAllowed({ flowType, cardType })) {
      this.setState({ error });
      return;
    }

    this.setState({ loading: true });

    if (unitNumber) {
      data.addressData.unit_door_full_number = unitNumber;
      data.addressData.unit_door_value = unitNumber;
      data.addressData.homeAddress += ` ${unitNumber}`;
      data.addressData.fullAddress += ` ${unitNumber}`;
      data.addressData.addressLine2 += ` ${unitNumber}`;
    }

    const confirmedData = omit({ ...data }, ['unitNumber']);
    setConfirmedInfo(confirmedData);

    const params = {
      ...confirmedData,
      location,
      flowType,
      countryCode: verifyDetails.countryCode
    };
    // format date
    APIs.store(params, isFlowV2DiffId)
      .then(
        ({
          status,
          type,
          msg,
          dataCheckOnConfirm = false,
          poaEnabled = false,
          chooseDiffId = false
        }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => onGoBack()
                    }
                  ]
                }
              });
            } else {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }

          if (chooseDiffId) {
            const error = {
              component: SelectAnotherIdException,
              props: {
                buttons: [
                  {
                    label: localizedString(
                      'dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_BUTTON_TEXT'
                    ),
                    onClick: () => {
                      this.setState({ showChooseAnotherId: true, error: null });
                    }
                  }
                ]
              }
            };
            this.setState({ error });
            return;
          }

          if (poaEnabled) {
            onShowProofOfAddress();
            return;
          }

          onNextStep({ dataCheckOnConfirm });
        }
      )
      .catch(({ message }) => {
        console.error(message);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              retake();
            }
          }
        };
        this.setState({ error });
      });
  }

  async handleChange(id, value) {
    if (!ID_NUMBER_EDITABLE && ['idNumber', 'licenceNumber', 'passportNumber'].includes(id)) {
      this.showNotEditableIdNumberError();
      return;
    }

    this.setState(({ data }) => {
      const dataToUpdate = {
        ...data,
        [id]: value
      };

      if (['licenceNumber', 'passportNumber'].includes(id) && dataToUpdate.idNumber) {
        dataToUpdate.idNumber = value;
      }

      return {
        data: dataToUpdate
      };
    });
  }

  handleGoBack() {
    const { setFrontIDParams, retake } = this.props;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  handleFormUpdate = ({ isValid, errors, setError, fields = {}, setField }) => {
    const { data = {} } = this.state;
    this.setState({
      hasFormErrors: !isValid
    });

    if (errors.tooManyFieldsEdited) {
      this.showTooManyFieldsEditedError();

      // Just reset the form with previous value
      const ignoreFields = [
        'addressData',
        'homeAddress',
        'addressLine1',
        'addressLine2',
        'suburb',
        'postcode',
        'state_territory',
        'tooManyFieldsEdited',
        'isMatch'
      ];
      for (const field in fields) {
        if (!ignoreFields.includes(field)) {
          setField({ [field]: data[field] });
        }
      }
      setError({ tooManyFieldsEdited: null });
    }
  };

  showTooManyFieldsEditedError = () => {
    let editTooManyFieldsButtons = [];
    if (FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE) {
      editTooManyFieldsButtons = [
        {
          label: localizedString('tryAgain'),
          onClick: () => {
            this.onRecaptureClick();
          }
        }
      ];
    } else {
      editTooManyFieldsButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => this.setState({ error: null })
        },
        {
          label: localizedString('recapture'),
          onClick: () => {
            this.onRecaptureClick();
          }
        }
      ];

      if (DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY) {
        editTooManyFieldsButtons.shift();
      }
    }

    const error = {
      component: EditTooManyFields,
      props: {
        buttons: editTooManyFieldsButtons
      }
    };
    this.setState({ error });
  };

  showNotEditableIdNumberError = () => {
    document.activeElement.blur(); // hide keyboard
    const error = {
      component: IdNumberNotEditable,
      props: {
        buttons: [
          {
            label: localizedString('back'),
            variant: 'outline',
            onClick: () => this.setState({ error: null })
          },
          {
            label: localizedString('recapture'),
            onClick: () => {
              this.onRecaptureClick();
            }
          }
        ]
      }
    };
    this.setState({ error });
  };

  onRecaptureClick = () => {
    const { setEditedFields, setFrontIDParams, retake } = this.props;

    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    setEditedFields([]);
    if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
      const error = {
        component: TooManyRetryAttempts,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              large: true,
              shadow: true,
              onClick: () => {
                setCookie('idCaptureAttempt', 0, -7);
                this.setState({ error: null });
              }
            }
          ]
        }
      };

      this.setState({ error });
      return;
    }
    setFrontIDParams({});
    retake();
  };

  renderConfirmLeaveChanges = () => {
    const { showConfirmLeaveEditing } = this.state;

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ showConfirmLeaveEditing: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          const state = { ...this.state.backup };
          state.backup = null;
          this.setState({ ...state });
        }
      }
    ];

    return (
      <Modal
        isOpen={showConfirmLeaveEditing}
        heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
        buttons={confirmBtns}
      >
        {localizedString('willLoseChangesOnScreen')}
      </Modal>
    );
  };

  renderNoMiddleNameModal = () => {
    const { showNoMiddleNameModal } = this.state;
    const confirmBtns = [
      {
        label: localizedString('addNow'),
        variant: 'transparent',
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false
          });
        }
      },
      {
        label: localizedString('noMiddleName'),
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false
          });
        }
      }
    ];

    return (
      <Modal
        isOpen={showNoMiddleNameModal}
        heading={localizedString('noMiddleNameQuestion')}
        buttons={confirmBtns}
      >
        {localizedString('noMiddleNameDesc1')}
        <br />
        <br />
        {localizedString('noMiddleNameDesc2')}
      </Modal>
    );
  };

  handleChooseDiffId = (newDoc) => {
    const { onChooseDiffId } = this.props;
    onChooseDiffId(newDoc);
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      error,
      data,
      loading,
      countryOfIssue,
      hasFormErrors,
      userEdited,
      useIdNumber,
      showChooseAnotherId
    } = this.state;

    const { component: Error, props: errorProps } = error || {};
    const { idType, onExit, verifyDetails, appConfig, isOldTurkishDL, editedFields } = this.props;

    const footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: onExit,
        dataTestId: 'details-back'
      },
      {
        label: loading
          ? localizedString('loading')
          : localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON'),
        type: 'submit',
        disabled:
          (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !data.checkConfirm) || hasFormErrors || loading,
        variant: loading ? 'transparent' : 'secandary',
        loading,
        dataTestId: 'details-confirm'
      }
    ];

    const currentDoc = {
      type: data.cardType
    };

    if (showChooseAnotherId) {
      return <ChooseAnotherId currentDoc={currentDoc} onNextStep={this.handleChooseDiffId} />;
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}
        <Form onUpdate={this.handleFormUpdate} onSubmit={this.handleConfirm}>
          <Page
            buttons={footerButtons}
            footerShadow
            onClose={() => {
              if (editedFields.length > 0) {
                this.setState({ showConfirmLeaveEditing: true });
              } else {
                this.setState(({ backup }) => ({
                  ...backup,
                  backup: null
                }));
              }
            }}
          >
            <VerifyDetailsContent
              {...data}
              engine4Config={appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null}
              idType={idType}
              cardType={data.cardType}
              useIdNumber={useIdNumber}
              isOldTurkishDL={isOldTurkishDL}
              country={verifyDetails.countryCode}
              countryOfIssue={countryOfIssue}
              onChange={(id, value) => {
                if (!userEdited) {
                  this.setState({ userEdited: true });
                }
                this.handleChange(id, value);
              }}
              isSubmiting={loading}
            />
            {this.renderConfirmLeaveChanges()}
            {this.renderNoMiddleNameModal()}
          </Page>
        </Form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  const { information, capture, appConfig, edited } = state;
  return {
    extracted: information.extracted,
    lastConfirmedDetails: information.lastConfirmedDetails,
    frontParams: capture.frontParams,
    editedFields: edited.fields,
    appConfig,
    eligibleAge: selectEligibleAge(state)
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setConfirmedInfo: (data) => dispatch(InfoAction.setConfirmedInfo(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
