import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import APIs from '@services/APIs';

import experianService from './experianService';
import addyService from './addyService';
import addressFinderService from './addressFinderService';
import loqateService from './loqateService';

const BASE_URL = APIs.API_URL;

const ADDRESS_SERVICES_IN_PRIORITY_ORDER = [
  loqateService,
  experianService,
  addyService,
  addressFinderService
];

export default {
  find,
  verify,
  isAddressAPIDisabled
};

async function getAvailableBackendServices() {
  try {
    const { data } = await axios.get(`${BASE_URL}/address/service`);
    return data.addressValidators;
  } catch (error) {
    console.error(error);
    throw new Error('Error trying to get available address services');
  }
}

async function getMatchedService(country) {
  if (isEmpty(country)) {
    throw new Error('No country specified');
  }

  const availableBackendServices = await getAvailableBackendServices();

  if (!isArray(availableBackendServices) || isEmpty(availableBackendServices)) {
    throw new Error('No available backend address services');
  }

  const addressService = ADDRESS_SERVICES_IN_PRIORITY_ORDER.find((service) => {
    return (
      service.canHandleCountry(country) && availableBackendServices.includes(service.getName())
    );
  });

  if (addressService) {
    return addressService;
  }

  throw new Error('No match between countries and/or available address services');
}

async function find({ address, country, idHash }) {
  try {
    const addressService = await getMatchedService(country);
    return addressService.find({ address, country, idHash });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function verify({ address, country, idHash }) {
  try {
    const addressService = await getMatchedService(country);
    return addressService.verify({ address, country, idHash });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function isAddressAPIDisabled(country) {
  try {
    const result = await getMatchedService(country);
    return isEmpty(result);
  } catch (error) {
    console.error(error);
    return true;
  }
}
