import { localizedString } from '@languages';
import { VOI_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME } from '@spotMobileConfig';

import { getFieldsValidation } from '@lib/validations/vefifyDetailsFormValidations';

export function getValidations({ idType, countryOfIssue = '', values = {}, flowType, cardType }) {
  const validations = getFieldsValidation({ idType, countryOfIssue, values, flowType, cardType });

  if (VOI_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME) {
    validations.middleName = {
      middleName: {
        ...validations.middleName.middleName,
        isMinLength: {
          message: localizedString('pleaseEnterFullMiddleName'),
          length: 2
        }
      }
    };
  }

  return validations;
}
