import { memo } from 'react';
import PropTypes from 'prop-types';

export const HighlightedAddressItem = memo(({ address = '', highlight }) => {
  const highlightRanges = highlight.split(',').map((range) => {
    const [from, to] = range.split('-').map((s) => parseInt(s, 10));
    return { from, to };
  });

  const splitAddress = highlightRanges
    .reduce(
      ({ result, cursor }, { from, to }, index) => {
        result.push({ text: address.slice(cursor, from), isHighlighted: false, from: cursor });
        result.push({ text: address.slice(from, to + 1), isHighlighted: true, from });

        const lastIteration = index === highlightRanges.length - 1;
        const allTextCovered = to >= address.length - 1;

        if (lastIteration && !allTextCovered) {
          result.push({ text: address.slice(to + 1), isHighlighted: false, from: to + 1 });
        }

        return { result, cursor: to + 1 };
      },
      { result: [], cursor: 0 }
    )
    .result.filter(({ text }) => !!text);

  return (
    <>
      {splitAddress.map(({ text, isHighlighted, from }) => {
        return <span key={`${text}--${from}`}>{isHighlighted ? <b>{text}</b> : text}</span>;
      })}
    </>
  );
});

HighlightedAddressItem.propTypes = {
  address: PropTypes.string,
  highlight: PropTypes.string
};
