const DEFAULT_ELIGIBLE_AGE = 18;

export const isAgeEligible = (age, eligibleAge = DEFAULT_ELIGIBLE_AGE) => {
  return eligibleAge ? age >= eligibleAge : true;
};

export const selectEligibleAge = (state) => {
  const perChannelConfig = parseInt(state?.appConfig?.ageAcceptance, 10);

  if (!perChannelConfig && perChannelConfig !== 0) {
    return DEFAULT_ELIGIBLE_AGE;
  }

  return perChannelConfig;
};
